import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router";
import qs from "query-string";
import { createContext } from "react";
import Layout from "./components/Layout.js";
import NoColor from "./wrappers/NoColor.js";
import HomePage from "./views/HomePage.js";
import GameLoop from "./views/GameLoop.js";
import MintPage from "./views/MintPage.js";
import MoreInfo from "./views/MoreInfo.js";
import Tokenomics from "./views/Tokenomics.js";
import { MetaMaskWrapper } from "./wrappers/MetaMaskWrapper.js";
import Funds from "./views/Funds.js";
import MintV2Page from "./views/MintPage_v2.js";
import { Skins } from "./views/Skins.js";
import SkinMintPage from "./views/SkinMintPage.js";
import { HelmetProvider } from "react-helmet-async";
import Tutorial from "./views/Tutorial.js";
import { SkinGamePage } from "./views/SkinGamePage.js";
import { Skin_Xmas_Page } from "./views/Skin_Xmas.js";
import { useQueries } from "react-query";
import { q_token_prices } from "./queries/queries.js";
import { getv } from "./utils/utils.js";
import { BikeBurnPage } from "./views/BikeBurn.js";
import { PrizeboxPage } from "./views/PrizeboxPage.js";
import MintFactionAgentsPage from "./views/MintFactionAgentsPage.js";
import { ChainPlayPage } from "./views/ChainPlayPage.js";
import AccountWrapper from "./wrappers/AccountWrapper.js";
import { Sponsor_RAS } from "./views/Sponsor_RAS.js";
import { ThirdWebLoginWrapper } from "./views/ThirdWebLogin.js";
import { SeasonPassPage } from "./views/SeasonPassPage.js";
import { TP_GiftCardPage } from "./views/TP_GiftCard.js";

export const AppContext = createContext({});
export const useAppContext = () => useContext(AppContext);

export const NowContext = createContext({ now: null });
export const useNowContext = () => useContext(NowContext);

const tokdecnmap = {
  WETH: 6,
  weth: 6,
  ETH: 6,
  DEZ: 0,
  USD: 2,
  "weth-mock01": 6,
  lc01: 6,
  lc02: 2,
  lc: 3,
  LC: 3,
};

export const tokdecn = (token) => tokdecnmap[token] ?? 2;
const tokedecn2map = {
  WETH: 3,
  weth: 3,
  ETH: 3,
  DEZ: 0,
  USD: 2,
  "weth-mock01": 3,
  lc01: 3,
  lc02: 2,
  lc: 2,
  LC: 2,
};
export const tokdecn2 = (token) => tokedecn2map[token] ?? 2;

function App() {
  const history = useNavigate();
  const location = useLocation();
  const psearch = qs.parse(location.search);

  const upd_psearch = (upd) => {
    let se = qs.stringify({ ...psearch, ...upd }, { arrayFormat: "bracket" });
    history(`${location.pathname}?${se}`);
  };

  const [now, set_now] = useState(Date.now());
  useEffect(() => {
    setTimeout(() => set_now(Date.now()), 200);
  }, [now]);

  const [qo_tokpri] = useQueries([q_token_prices()]);
  const tokmap = useMemo(
    () => getv(qo_tokpri, "data.result") || { ethusd: 0, dezusd: 0 },
    [qo_tokpri.dataUpdatedAt],
  );

  const tokkey = (c) => (c == "WETH" ? "ethusd" : c == "DEZ" ? "dezusd" : null);
  const tok_to_usd_val = (cost, token) => {
    let k = tokkey(token);
    if (!k) return null;
    let tokp = tokmap[k];
    return parseFloat(tokp) * parseFloat(cost);
  };
  const usd_to_tok_val = (cost, token) => {
    let k = tokkey(token);
    if (!k) return null;
    let tokp = tokmap[k];
    return parseFloat(cost) / parseFloat(tokp);
  };

  const appcon = {
    history,
    location,
    path: location.pathname,
    psearch,
    upd_psearch,

    tokmap,
    tok_to_usd_val,
    usd_to_tok_val,
  };

  return (
    <div className="App bg-dark text-white">
      <NowContext.Provider value={{ now }}>
        <AppContext.Provider value={appcon}>
          <MetaMaskWrapper>
            <ThirdWebLoginWrapper>
              <AccountWrapper>
                <Layout>
                  <HelmetProvider>
                    <Routes>
                      <Route path="/gameloop" exact element={<GameLoop />} />
                      <Route path="/mint" exact element={<MintPage />} />
                      <Route path="/mint_v2" exact element={<MintV2Page />} />
                      <Route
                        path="/factions"
                        exact
                        element={<MintFactionAgentsPage />}
                      />
                      <Route
                        path="/bikeburn"
                        exact
                        element={<BikeBurnPage />}
                      />
                      <Route
                        path="/prizebox"
                        exact
                        element={<PrizeboxPage />}
                      />

                      <Route
                        path="/tp_giftcard"
                        exact
                        element={<TP_GiftCardPage />}
                      />

                      <Route path="/skins" exact element={<Skins />} />
                      <Route
                        path="/skinmint"
                        exact
                        element={<SkinMintPage />}
                      />
                      <Route
                        path="/skingame"
                        exact
                        element={<SkinGamePage />}
                      />
                      <Route
                        path="/skin-xmas"
                        exact
                        element={<Skin_Xmas_Page />}
                      />

                      <Route
                        path="/seasonpass"
                        exact
                        element={<SeasonPassPage />}
                      />

                      <Route path="/moreinfo" exact element={<MoreInfo />} />
                      <Route
                        path="/tokenomics"
                        exact
                        element={<Tokenomics />}
                      />
                      <Route path="/funds" exact element={<Funds />} />
                      <Route path="/tutorial" exact element={<Tutorial />} />
                      <Route path="/" exact element={<HomePage />} />
                    </Routes>
                  </HelmetProvider>
                </Layout>
                <NoColor />
              </AccountWrapper>
            </ThirdWebLoginWrapper>
          </MetaMaskWrapper>
        </AppContext.Provider>
      </NowContext.Provider>
    </div>
  );
}

export default App;
