import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { tokdecn, tokdecn2, useAppContext } from "../App";
import {
  faCheck,
  faChevronRight,
  faCircle,
  faClose,
  faLock,
  faSpinner,
  faUsd,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useNowContext } from "../App";
import {
  Array2D_Tablecn,
  Card,
  Img,
  InpText,
  Tag,
  TokenIcon,
} from "../components/utilityComps";
import {
  polytxnlink,
  qissuccesss,
  q_que_txn,
  q_sheet_pages_chainplay,
  q_seasonpass_info,
  q_seasonpass_txns,
  q_seasonpass_passlist,
  q_factions_agents_owns,
  q_seasonpass_allot,
} from "../queries/queries";
import { tablecn } from "../utils/cn_map";
import { get_skin_transparent_img, polychainimg } from "../utils/links";
import {
  cdelay,
  dec,
  from_time_mini,
  getv,
  iso_format,
  jstr,
  nils,
  toeth,
  tofeth,
} from "../utils/utils";
import { useMetaContext } from "../wrappers/MetaMaskWrapper";
import { PopUp, PopupCloseBtn } from "../components/popup";
import { Loader01c } from "../components/anims";
import { SeasonPass } from "../contracts/SeasonPass/SeasonPass";
import {
  mm_asset_signer,
  t3_asset_signer,
  t3_contract_call,
} from "../contracts/contract_funcs";
import moment from "moment";
import { useQueries } from "react-query";
import { useThirdWebLoginContext } from "./ThirdWebLogin";
import { polygon } from "thirdweb/chains";
import { extract_inp } from "../components/input";
import { useLayoutContext } from "../components/Layout";

const PageContext = createContext({});
export const usePageContext = () => useContext(PageContext);

const page_w = "mx-auto w-[80rem] max-w-[98vw]";
const card_cn =
  "backdrop-blur-md bg-gradient-to-br from-purple-500/25 to-r2dark/25 text-white";
const header_cn =
  "bg-gradient-to-t from-purple-700/10 to-r2dark/20 backdrop-blur-xl h-[550px] py-[1rem]";
const highlight_txt = "font-digi text-purple-400";
const TableView = ({ head, d }) => {
  return (
    <div>
      {!nils(head) && (
        <p
          class={twMerge(
            highlight_txt,
            " resp-text-1 text-left font-digi mt-[1rem] mb-1",
          )}
        >
          {head}
        </p>
      )}
      <Card className={"card-dark-bg w-full rounded-xl"}>
        <table className={twMerge("thintdrowp4-table", "w-full")}>
          <thead>
            {(d || []).slice(0, 1).map((r) => {
              return (
                <tr className={highlight_txt}>
                  {r.map((e) => {
                    return <td class="text-left">{e}</td>;
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody>
            {(d || []).slice(1).map((r) => {
              return (
                <tr>
                  {r.map((e) => {
                    return <td class="text-left font-mon">{e}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    </div>
  );
};

const boxestypes = _.chain([
  [
    "base",
    "Base",
    "bg-gradient-to-tr from-r2dark to-acc4",
    "#C52FF8",
    "https://cdn.dnaracing.run/imgs/seasonpasstoken.png",
    "https://e0.pxfuel.com/wallpapers/28/614/desktop-wallpaper-tron-movie.jpg",
  ],
])
  .map((e) => {
    let i = 0;
    return {
      id: e[i++],
      name: e[i++],
      color: e[i++],
      hexcode: e[i++],
      img: e[i++],
      banner: e[i++],
      InfoComp: e[i++],
    };
  })
  .keyBy("id")
  .value();

const boxtheme = boxestypes["base"];

const BuyCard = ({
  info,
  boxtheme,
  enablebuy = true,
  unlocks_at,
  on_buy_click = () => {},
}) => {
  const { now } = useNowContext();
  const mmcon = useMetaContext();
  const { vault } = mmcon;
  const laycon = useLayoutContext();
  return (
    <div class="relative mx-auto xs:w-[15rem] lg:w-[25rem] res-text--1">
      <div className="fc-cc w-full flex-wrap relative">
        <div
          className={twMerge(
            "absolute aspect-[1/1] z-[0] blur-3xl",
            "top-[55%] left-[50%]",
            "translate-x-[-50%] translate-y-[-50%]",
            "md:w-[15rem] xs:w-[8rem]",
            boxtheme.color,
          )}
        ></div>
        <div
          style={{
            background: `linear-gradient(${boxtheme.hexcode} 0%, rgba(100, 100, 100, 0) 100%)`,
          }}
          className="mint-box-container"
        >
          <div
            className={twMerge(
              "img-obey-cont rotate-[10deg]",
              "mx-auto xs:w-[10rem] lg:w-[14rem] aspect-[1/1]",
              "absolute lg:top-[-7rem] xs:top-[-4rem]",
            )}
          >
            <img src={boxtheme.img} />
          </div>
          <div class="h-[7rem]"></div>
          <div class="fc-cc">
            <p class="font-digi resp-text-2">
              Mint {info.title} {info.title.includes("Pass") ? "" : "Pass"}
            </p>
          </div>
          <div class="my-[1rem]"></div>
          <div class="resp-text-2 font-digi">
            Buy for $ {dec(info.priceusd, 2)}
          </div>
          <div class="fr-sc w-full">
            <div class="flex-1"></div>
            <TokenIcon token={"WETH"} />
            <TokenIcon token={"DEZ"} />
          </div>
          {enablebuy ? (
            <>
              {nils(vault) ? (
                <Tag
                  onClick={() => {
                    laycon.open_loginpop();
                  }}
                  className="bg-orange-500 font-digi resp-text--1 text-white"
                >
                  {"Connect Wallet"}
                </Tag>
              ) : (
                <>
                  {info.locked == true ? (
                    <Tag
                      onClick={() => {
                        // on_buy_click();
                      }}
                      className="fr-sc resp-gap-1  bg-transparent text-red-500 font-digi resp-text--1"
                    >
                      <FontAwesomeIcon icon={faLock} />
                      <span>{"Locked"}</span>
                    </Tag>
                  ) : (
                    <Tag
                      onClick={() => {
                        on_buy_click();
                      }}
                      className="bg-purple-500 font-digi resp-text--1 text-white"
                    >
                      {"Buy Now"}
                    </Tag>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <p class="text-white resp-text-1 font-digi">
                {"Unlocks in "}
                {from_time_mini(unlocks_at, now)}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const HeadSection = ({}) => {
  const mmcon = useMetaContext();
  const { aumode, vault } = mmcon;

  const t3con = useThirdWebLoginContext();
  const { active_account } = t3con;

  const appcon = useAppContext();
  const { tokmap, usd_to_tok_val } = appcon;

  const ccon = usePageContext();
  const { q_info, q_txns, info: info, skin, unlocks_at, enablebuy } = ccon;

  const [pop, set_pop] = useState(false);
  const [popdata, set_popdata] = useState({});
  const [popresp, set_popresp] = useState({});

  const open_popup = () => {
    console.log("open buy popup");
    set_pop(true);
    set_popdata({});
    set_popresp({});
  };

  const close_popup = () => {
    set_pop(false);
    set_popdata({});
    set_popresp({});
  };

  const actions = {
    buy: async (popdata) => {
      try {
        const { token, qty } = popdata;
        console.log("buy", { token, qty });

        if (nils(token)) throw new Error("token not found");
        if (nils(qty)) throw new Error("qty not found");
        if (qty < 1) throw new Error("qty must be greater than 1");
        if (qty > info.max_qty)
          throw new Error(`qty must be less than ${info.max_qty}`);

        set_popresp({
          loading: true,
          msgtype: "info",
          msg: `Buying ${qty} Skins`,
        });

        const con =
          aumode == "thirdweb"
            ? await SeasonPass.get_contract({
                nosigner: true,
                rpc: polygon.rpc,
              })
            : await SeasonPass.get_contract();
        const paycon =
          aumode == "thirdweb"
            ? await t3_asset_signer(token)
            : await mm_asset_signer(token);

        let totcostusd = info.priceusd * qty;
        console.log({ totcostusd });

        let totcost = usd_to_tok_val(totcostusd, token);
        totcost = parseFloat(dec(totcost, 6));
        console.log({ price: totcost });

        set_popresp({
          loading: true,
          msgtype: "info",
          msg: `Checking Balance`,
        });
        let bal = await paycon.balanceOf(vault);
        bal = parseFloat(tofeth(bal));
        console.log({ bal });
        if (bal < totcost) throw new Error("not enough balance");

        let alw = await paycon.allowance(vault, con.contractAddress);
        alw = parseFloat(tofeth(alw));
        console.log({ alw });
        if (alw < totcost) {
          if (aumode == "thirdweb") {
            set_popresp({
              loading: true,
              msgtype: "info",
              msg: `Setting Allowance`,
            });
            let tx = await t3_contract_call(
              token,
              "approve",
              [con.contractAddress, toeth(bal * 0.9)],
              "txn",
              true,
              {
                active_account,
              },
            );
            await cdelay(2 * 1e3);
          } else {
            set_popresp({
              loading: true,
              msgtype: "info",
              msg: `Spend Allowance: Please confirm transaction on MetaMask`,
            });
            let tx = await paycon.approve(
              con.contractAddress,
              toeth(totcost * 1.2),
            );
            await tx.wait();
            await cdelay(2 * 1e3);
          }
        }

        set_popresp({
          loading: true,
          msgtype: "info",
          msg:
            aumode == "thirdweb"
              ? `Sending Transaction`
              : `Please confirm Buy transaction on MetaMask`,
        });
        let tx = null;
        if (aumode == "thirdweb") {
          tx = await t3_contract_call(
            "seasonpass",
            "buyPass",
            [info.name, qty, token],
            "txn",
            true,
            {
              active_account,
            },
          );
        } else {
          tx = await con.buyPass(info.name, qty, token);
          // tx = await tx.wait();
          console.log("buy response", tx);
          console.log("buy receipt", tx);
        }

        let queresp = await q_que_txn({
          hash: tx.hash,
          vault,
          type: "buy_seasonpass",
          service: "seasonpass",
        }).queryFn();
        console.log("queresp", queresp);

        set_popresp({
          loading: false,
          msgtype: "success",
          msg: `Txn Sucessful! your skins will be minted soon`,
        });
        // window.open(polytxnlink(tx.hash));

        setTimeout(() => {
          q_txns.refetch();
        }, 8 * 1e3);
        setTimeout(() => {
          close_popup();
        }, 2000);
      } catch (err) {
        console.log(err);
        let errmsg = !nils(err.reason) ? err.reason : err.message;
        if (errmsg.length > 100) errmsg = errmsg.slice(0, 100) + "...";

        set_popresp({
          loading: false,
          msgtype: "error",
          msg: `Error:${errmsg}`,
        });
        // setTimeout(() => {
        //   close_popup();
        // }, 2000);
      }
    },
  };

  const skincard = !nils(info) && (
    <>
      <BuyCard
        {...{
          boxtheme,
          info,
          enablebuy,
          unlocks_at,
          on_buy_click: () => {
            open_popup();
          },
        }}
      />
    </>
  );
  const side_info = !nils(info) && (
    <>
      <div class="fc-cc h-full resp-text-1">
        <p class="text-white font-digi resp-text-3">{info.title}</p>
      </div>
    </>
  );

  const popup_section = !nils(info) && (
    <PopUp
      {...{
        openstate: pop,
        overlayclose: false,
        onClose: close_popup,
        wrapcn: twMerge("top-[5rem] left-[50%]"),
        innercn: twMerge(" translate-x-[-50%] translate-y-[0%]"),
      }}
    >
      <Card
        className={twMerge(
          "card-dark-bg xs:w-[95vw] lg:w-[30rem]",
          "backdrop-blur-xl  rounded-xl resp-text--1",
        )}
      >
        <PopupCloseBtn closepopup={close_popup} />
        <p class={twMerge("font-digi resp-text-2 text-center my-2")}>
          Buy {info.name} Pass
        </p>
        <p className="text-slate-300 resp-text--3">{jstr(popdata)}</p>
        <div class="fr-cc">
          <InpText
            {...{
              id: "inp_qty",
              label: "Quantity",
              placeholder: "enter qty",
              def_val: null,
              setter: (v) => {
                v = parseInt(v);
                if (nils(v)) v = 0;
                set_popdata({ ...popdata, qty: v });
              },
            }}
          />
        </div>
        {popdata.qty > 0 && (
          <>
            <p className="fr-cc resp-text-1 w-full text-center my-2 text-white font-digi">
              <span>Total Cost: </span>
              <FontAwesomeIcon icon={faUsd} />
              <span>{dec(popdata.qty * info.priceusd, 2)}</span>
            </p>
            {popresp.loading == true ? (
              <p></p>
            ) : (
              <div className="fr-cc gap-2">
                <p className="font-digi resp-text-0">Buy Using:</p>
                {[
                  ["WETH", "bg-purple-500/40 text-white"],
                  ["DEZ", "bg-acc4/40 text-white"],
                ].map(([token, cn]) => {
                  return (
                    <Tag
                      onClick={async () => {
                        const fn = async () => {
                          set_popdata({ ...popdata, token });
                          actions.buy({ ...popdata, token });
                        };
                        fn();
                      }}
                      className={twMerge("font-digi fr-sc resp-gap-1", cn)}
                    >
                      <TokenIcon token={token} />
                      <span>{token}</span>
                    </Tag>
                  );
                })}
              </div>
            )}
          </>
        )}

        {!_.isEmpty(popresp) && (
          <div
            class={twMerge(
              "fr-sc w-full px-4 py-2 border-2 rounded-md",
              popresp.msgtype == "error"
                ? "text-red-400 border-red-400"
                : popresp.msgtype == "info"
                  ? "text-blue-400 border-blue-400"
                  : popresp.msgtype == "success"
                    ? "text-green-400 border-green-400"
                    : "",
            )}
          >
            {popresp.loading == true && <Loader01c size="s" />}
            <p class={"flex-1 font-digi resp-text--1"}>{popresp.msg}</p>
          </div>
        )}
      </Card>
    </PopUp>
  );

  if (!qissuccesss(ccon.q_info) || nils(info)) return null;
  return (
    <>
      {popup_section}
      <div class="xs:flex  lg:hidden fc-cc">
        {skincard}
        {side_info}
      </div>
      <div class="xs:hidden lg:block">
        <div class={header_cn}>
          <div
            class={twMerge(
              page_w,
              "h-full flex flex-row justify-around items-stretch",
            )}
          >
            {skincard}
            {side_info}
          </div>
        </div>
      </div>
    </>
  );
};

const TxRow = ({ tx }) => {
  let date =
    moment().diff(tx.date, "seconds") < 5 * 60
      ? moment(tx.date).fromNow()
      : iso_format(tx.date, "DD-MMM YY, h:mm:ss a");
  let hash = tx.id.split(":")[0];

  const amt = getv(tx, "connects.amt");
  const token = getv(tx, "connects.token");

  const td_amt = (
    <div className="fr-sc resp-gap-1 resp-text--2">
      <div className="flex-1"></div>
      <div className="xs:w-[1rem] lg:w-[2rem]">
        <TokenIcon token={token ?? "WETH"} />
      </div>
      <span>{dec(amt, tokdecn(token))}</span>
    </div>
  );

  const td_polyimg = (
    <Tag redirect={polytxnlink(hash)} className="xs:w-[1.5rem] lg:w-[3rem]">
      <Img img={polychainimg} />
    </Tag>
  );

  const td_names = <div className="fc-ss resp-gap-1 resp-text--2"></div>;

  const td_type = (
    <span className="resp-text--2">
      {_.chain(tx.type).split("_").map(_.upperCase).join(" ").value()}
    </span>
  );

  const td_date = <span className="resp-text--2">{date}</span>;

  const qty = getv(tx, "connects.qty");

  const allminted = getv(tx, "connects_post.minted") === true;

  const td_inout = (
    <>
      {tx.type == "buy" && (
        <>
          <Tag className="fr-sc resp-gap-2 bg-blue-500/20 text-white">
            <FontAwesomeIcon icon={faCheck} />
            {qty} minted
          </Tag>
        </>
      )}
    </>
  );

  const asset_groups = useMemo(() => {
    let assets = getv(tx, "connects.assets") || [];
    assets = assets.map((e) => {
      e.post = getv(tx, `connects_post.mintmap.${e.idx}`);
      return e;
    });
    return _.groupBy(assets, "skin");
  }, [jstr(tx)]);

  const td_view = <>{tx.type == "buy" && <></>}</>;

  return (
    <>
      <tr className={"thintdrow xs:hidden md:block"}>
        <td className={""}>{td_polyimg}</td>
        <td className={""}>{td_type}</td>
        <td className={""}>{td_amt}</td>
        <td className={""}>{td_date}</td>
        <td className={""}>{td_inout}</td>
        <td className={""}>{td_view}</td>
      </tr>
      <tr
        className={"thintdrow xs:block md:hidden border-b border-transparent"}
      >
        <td className={""} colSpan={2}>
          {td_polyimg}
        </td>
        <td className={""} colSpan={2}>
          {td_amt}
        </td>
        <td className={""} colSpan={2}>
          {td_date}
        </td>
      </tr>
      <tr className={"thintdrow xs:block md:hidden border-b border-acc0"}>
        <td className="" colSpan={6}>
          <div className="fr-sc">
            {td_inout}
            {td_view}
          </div>
        </td>
      </tr>
    </>
  );
};

const PassCard = ({ p, ag, ags, agmap, passlist }) => {
  const ccon = usePageContext();
  const { q_passlist } = ccon;
  const [pop, set_pop] = useState(false);
  const [resp, set_resp] = useState({});

  const close_popup = () => {
    set_pop(false);
    set_resp({});
  };
  const agids = _.map(ags, (e) => e.agid);
  const allot_agent = async (agid) => {
    try {
      set_resp({ type: "loading", msg: "Allotting Agent..." });
      let r = await q_seasonpass_allot({
        passid: p.passid,
        agid: agid,
      }).queryFn();
      if (r.status == "success") {
        set_resp({ type: "success", msg: "Agent allotted to season pass" });
        setTimeout(() => {
          set_pop(false);
          q_passlist.refetch();
        }, 2000);
      } else {
        set_resp({ type: "error", msg: `Failed! ${getv(r, "err")}` });
      }
    } catch (err) {
      set_resp({ type: "error", msg: err.message });
    }
    setTimeout(() => {
      set_resp({});
    }, 4000);
  };

  const search_id = "seasonpass-hid-search";
  const selhids_searchtxt = extract_inp(search_id);

  const exisalotted = useMemo(() => {
    return _.chain(passlist).map("agid").compact().value();
  }, [jstr(passlist)]);
  const filtagids = useMemo(() => {
    let searchtxt = selhids_searchtxt;
    if (nils(searchtxt))
      return _.chain(agids)
        .filter((e) => !exisalotted.includes(e))
        .value();

    let sech_hid = parseInt(searchtxt);
    if (nils(sech_hid)) sech_hid = null;
    let sear = _.lowerCase(searchtxt);

    let filt = _.chain(agids)
      .map((agid) => {
        if (exisalotted.includes(agid)) return null;
        let ag = agmap[agid];
        let hname = _.lowerCase(ag.name);
        if (!nils(sech_hid) && sech_hid == ag.agid) return [agid, 1];
        else if (
          !nils(sech_hid) &&
          ag.hid.toString().startsWith(sech_hid.toString())
        )
          return [agid, 2];
        else if (hname.startsWith(sear)) return [agid, 3];
        else if (hname.includes(sear)) return [agid, 4];
        else return null;
      })
      .compact()
      .sortBy((e) => e[1])
      .map(0)
      .value();
    return filt;
  }, [jstr(agmap), jstr(agids), selhids_searchtxt, jstr(exisalotted)]);

  return (
    <div class="relative overflow-hidden rounded-md border border-acc4 w-full card-basic-bg resp-text--1 p-2">
      <p
        style={{
          // round bottom left
          borderRadius: "0 0 0 1rem",
          top: 0,
          right: 0,
          transform: "translate(0.2rem, -0.2rem)",
        }}
        className="absolute resp-text-0 font-digi bg-r2dark/60 p-2"
      >
        #{p.passid}
      </p>

      {nils(p.agid) && (
        <>
          <div class="absolute top-[40%] fr-cc w-full">
            <Tag
              onClick={() => {
                set_pop(true);
              }}
              className="fr-cc resp-gap-1 bg-r2dark/80 text-white"
            >
              <span className="resp-text-2 font-digi">Allot Agent</span>
            </Tag>
          </div>

          <PopUp
            {...{
              openstate: pop,
              overlayclose: false,
              wrapcn: "top-[6rem]",
              innercn: "translate-y-[0%]",
            }}
          >
            <Card
              className={twMerge(
                "card-basic-bg card-grad-border xs:w-[95vw] lg:w-[50rem] ",
              )}
            >
              <PopupCloseBtn closepopup={close_popup} />
              <div class="fr-sc w-full">
                <InpText
                  {...{
                    id: search_id,
                    setter: () => {},
                    contprops: {
                      className: "w-full bg-r2dark",
                    },
                    inpprops: {
                      className: "w-full",
                    },
                    placeholder: "Search by #AgentID or Agent Name",
                    autoComplete: "off",
                  }}
                />
              </div>
              {!_.isEmpty(resp) ? (
                <div
                  class={twMerge(
                    "fr-sc w-full px-4 py-2 my-2 bg-r2dark/80 border-2 rounded-md",
                    resp.type == "error"
                      ? "text-red-400 border-red-400"
                      : resp.type == "loading"
                        ? "text-blue-400 border-blue-400"
                        : resp.type == "info"
                          ? "text-blue-400 border-blue-400"
                          : resp.type == "success"
                            ? "text-green-400 border-green-400"
                            : "",
                  )}
                >
                  {resp.msgtype == "loading" && <Loader01c size="s" />}
                  <p class="resp-text-0 font-digi">{resp.msg}</p>
                </div>
              ) : null}
              <div class={"xs:h-[60vh] lg:h-[50rem] overflow-auto"}>
                <div class="grid xs:grid-cols-3 lg:grid-cols-3 p-4 gap-4 ">
                  {filtagids.map((agid) => {
                    let ag = agmap[agid];
                    return (
                      <div
                        onClick={() => {
                          allot_agent(agid);
                        }}
                        class="w-full cursor-pointer h-full resp-text-1 relative "
                      >
                        <div class="absolute bottom-[1rem] w-full fr-cc resp-text-1 bg-r2dark/80 font-digi">
                          {`#${ag.agid}-${ag.name}`}
                        </div>
                        <div class="xs:h-auto lg:h-[20rem] aspect-[12/24] mx-auto">
                          <Img
                            img={`https://cdn.dnaracing.run/fbike-factions-agents/${ag.img}.png`}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Card>
          </PopUp>
        </>
      )}

      {!nils(ag?.img) ? (
        <div class="xs:h-[10rem] lg:h-[20rem] p-2 aspect-[12/24] mx-auto">
          <Img
            img={`https://cdn.dnaracing.run/fbike-factions-agents/${ag.img}.png`}
          />
        </div>
      ) : (
        <div class="xs:h-auto lg:h-[20rem] p-2 aspect-[1/1] mx-auto">
          <Img
            img={
              "https://cdn.dnaracing.run/imgs/seasonpasstoken02.png"
            }
          />
        </div>
      )}

      {!nils(p.agid) && !_.isEmpty(ag) && (
        <p
          style={{
            // round bottom left
            borderRadius: "0 1rem 0 0",
            bottom: 0,
            left: 0,
            transform: "translate(0.2rem, 0.2rem)",
          }}
          className="absolute resp-text-0 font-digi bg-r2dark/60 p-2"
        >
          #{ag.agid}-{ag.name}
        </p>
      )}
    </div>
  );
};

const PassList = () => {
  const { vault } = useMetaContext();
  const ccon = usePageContext();
  const { q_passlist } = ccon;
  const passlist = useMemo(
    () => getv(q_passlist, "data.result", []),
    [q_passlist],
  );
  const [qoags] = useQueries([
    q_factions_agents_owns({ vault }, { enabled: !nils(vault) }),
  ]);
  const [ags, agmap] = useMemo(() => {
    let ags = getv(qoags, "data.result");
    let agmap = _.keyBy(ags, "agid");
    console.log("agmap", agmap);
    return [ags, agmap];
  }, [qoags.dataUpdatedAt]);

  if (_.isEmpty(passlist)) return null;

  return (
    <div class="my-2 xs:max-w-[98vw] lg:max-w-[60rem] mx-auto">
      <div class="grid xs:grid-cols-2 lg:grid-cols-3 gap-2">
        {passlist.map((p) => {
          if (!p) return null;
          let ag = getv(agmap, p.agid);
          return <PassCard {...{ p, ag, ags, agmap, passlist }} />;
        })}
      </div>
    </div>
  );
};

const Transactions = ({ txns }) => {
  return (
    <Card className={"mx-auto card-basic-bg xs:w-full md:w-max  overflow-auto"}>
      {_.isEmpty(txns) ? (
        <p className="resp-text--1">No Transactions Yet</p>
      ) : (
        <table className={twMerge(Array2D_Tablecn.table_cn, "w-full")}>
          <tbody>
            {(txns || []).map((tx) => {
              return <TxRow tx={tx} />;
            })}
          </tbody>
        </table>
      )}
    </Card>
  );
};

export const SeasonPassPage = () => {
  const [q] = useQueries([q_sheet_pages_chainplay({}, { enabled: false })]);
  const o = useMemo(() => getv(q, "data.result"), [q.dataUpdatedAt]);

  const name = "Season03";
  const { vault } = useMetaContext();
  const [q_info, q_txns, q_passlist] = useQueries([
    q_seasonpass_info({
      name,
    }),
    q_seasonpass_txns(
      { vault, name },
      {
        enabled: !nils(vault),
        staleTime: 30 * 1e3,
        refetchInterval: 30 * 1e3,
      },
    ),
    q_seasonpass_passlist(
      { vault, name },
      {
        enabled: !nils(vault),
        staleTime: 30 * 1e3,
        refetchInterval: 30 * 1e3,
      },
    ),
  ]);
  const info = useMemo(
    () => getv(q_info, "data.result"),
    [q_info.dataUpdatedAt],
  );
  const txns = useMemo(
    () => getv(q_txns, "data.result"),
    [q_txns.dataUpdatedAt],
  );

  const unlocks_at = null;
  const enablebuy = true;

  const ccon = {
    vault,
    name,
    unlocks_at,
    enablebuy,
    q_info,
    info,
    q_txns,
    txns,
    q_passlist,
  };

  return (
    <>
      <PageContext.Provider value={ccon}>
        <div class="h-page">
          <HeadSection />
          <div class={page_w}>
            <div class="h-[1rem]"></div>
            {qissuccesss(q) && (
              <>
                <div class="grid xs:grid-cols-3 p-2  lg:grid-cols-6 gap-2 resp-text--1  text-slate-200">
                  <div class="col-span-3">
                    <Card className={twMerge(card_cn, " w-full h-full")}>
                      <div
                        class={twMerge(
                          highlight_txt,
                          "resp-text-1 font-digi text-center",
                        )}
                      >
                        {getv(o, "grid1.head")}
                      </div>
                      <hr className="my-2" />
                      <>
                        {(getv(o, "grid1.info") || []).map((r) => {
                          return (
                            <div class="fr-sc w-full resp-gap-2">
                              <FontAwesomeIcon
                                className="text-[0.5rem]"
                                icon={faCircle}
                              />
                              {r.map((e) => {
                                return <p class="my-1 font-mon px-1">{e}</p>;
                              })}
                            </div>
                          );
                        })}
                      </>
                      <hr className="my-2" />

                      <TableView
                        head="Rounds"
                        d={getv(o, "grid1.rounds") || []}
                      />

                      <TableView
                        head="Segments"
                        d={getv(o, "grid1.segments") || []}
                      />
                    </Card>
                  </div>
                  <div class="col-span-3">
                    <Card className={twMerge(card_cn, " w-full h-full")}>
                      <div
                        class={twMerge(
                          highlight_txt,
                          "resp-text-1 font-digi text-center",
                        )}
                      >
                        {getv(o, "grid2.head")}
                      </div>
                      <TableView
                        head="Scoring"
                        d={getv(o, "grid2.scoring") || []}
                      />
                      <div class="flex flex-row justify-around items-start gap-2">
                        <TableView head="" d={getv(o, "grid2.posmap") || []} />
                        <TableView head="" d={getv(o, "grid2.overall") || []} />
                      </div>
                    </Card>
                  </div>
                </div>
              </>
            )}

            <PassList />
            {!_.isEmpty(txns) && <Transactions txns={txns} />}
            <div class="h-[5rem]"></div>
          </div>
        </div>
      </PageContext.Provider>
    </>
  );
};
