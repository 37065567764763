import _ from "lodash";
import { fpost } from "./fetch.js";
import { getv, jstr } from "../utils/utils.js";
import { useEffect, useMemo, useState } from "react";
import { useQueries } from "react-query";
import {
  token_locpath,
  tokenacc_locpath,
} from "../wrappers/MetaMaskWrapper.js";

const usedevloc = true;

export const qiserr = (q) => {
  if (getv(q, "data.err")) return getv(q, "data.err");
  if (q.status == "error") return "server error";
  if (q.status == "loading") return false;
  return false;
};

export const iserr = (d) => {
  if (getv(d, "err") || d.status == "error") return getv(d, "data.err");
  return false;
};

export const qissuccess = (q) => {
  return getv(q, "status") == "success" && !qiserr(q);
};

export const qissuccesss = (q) => {
  return (
    getv(q, "status") == "success" &&
    getv(q, "data.status") == "success" &&
    !qiserr(q)
  );
};
export const q_auth_get_nonce = async (doc) => {
  let api = `${btbk}/fbike/auth/get_nonce`;
  return fpost(api, doc, {});
};

export const q_auth_verify_nonce = async (doc) => {
  let api = `${btbk}/fbike/auth/verify_nonce`;
  return fpost(api, doc, {});
};

export const q_auth_verify_token = async (doc) => {
  let api = `${btbk}/fbike/auth/verify_token`;
  return fpost(api, doc, {});
};

export const useStepQuery = ({
  key,
  par_ar,
  q_,
  params,
  lim = 1,
  options = {},
  ...props
}) => {
  // useEffect(() => console.log("useStepQuery", par_ar), [par_ar]);

  const [enabled_n, set_enabled_n] = useState(lim);
  const qs = useQueries(
    par_ar?.map((e, idx) => {
      let ext_options = { ...options, enabled: idx < enabled_n };
      // return () => {return { test: 0 }};
      return q_(...e, ext_options);
    }),
  );
  const c = useMemo(() => {
    let ob = _.countBy(qs, (e) => e.status);
    return {
      success: ob.success ?? 0,
      loading: ob.loading ?? 0,
      error: ob.error ?? 0,
      idle: ob.idle ?? 0,
    };
  }, [qs]);

  useEffect(() => {
    set_enabled_n((parseInt(c.success / lim) + 1) * lim);
  }, [c.success]);

  const datas = useMemo(() => {
    // if (qs_count.loading !== 0) return [];
    let ob = _.chain(qs).filter({ status: "success" }).map("data").value();
    // console.log("all_horses_data", ob);
    return ob;
  }, [jstr(_.map(qs, "dataUpdatedAt")), c]);

  return {
    qs,
    datas,
    c,
    n: par_ar.length,
    done: c.success,
    tot: par_ar.length,
    loading: c.idle + c.loading > 0,
  };
};

export const btbk =
  usedevloc && process.env.NODE_ENV == "development"
    ? `http://localhost:3005`
    : `https://api.dnaracing.run`;

export const polytxnbase = `https://www.polygonscan.com/tx`;
export const polytxnlink = (hash) => `${polytxnbase}/${hash}`;
export const polymaintxnlink = (hash) =>
  `${"https://www.polygonscan.com/tx"}/${hash}`;
export const polytxnidlink = (id) =>
  `${polytxnbase}/${id ? id.split(":")[0] : null}`;

export const req_auth_get_nonce = async (doc) => {
  let api = `${btbk}/fbike/auth/get_nonce`;
  return fpost(api, doc, {});
};

export const req_auth_verify_nonce = async (doc) => {
  let api = `${btbk}/fbike/auth/verify_nonce`;
  return fpost(api, doc, {});
};

export const req_auth_verify_token = async (doc) => {
  let api = `${btbk}/fbike/auth/verify_token`;
  return fpost(api, doc, {});
};

export const q_construct = (k, api, doc = {}, ext = {}) => {
  return {
    queryKey: [k, jstr(doc)],
    queryFn: () => {
      return fpost(api, doc);
    },
    staleTime: 1e14,
    ...ext,
  };
};

export const q_auconstruct = (k, api, doc = {}, ext = {}) => {
  return {
    queryKey: [k, jstr(doc)],
    queryFn: () => {
      let headers = {
        "x-vault": localStorage.getItem(tokenacc_locpath),
        "x-token": localStorage.getItem(token_locpath),
      };
      return fpost(api, doc, headers);
    },
    staleTime: 1e14,
    ...ext,
  };
};

export const q_lootbox_base = (doc) => {
  let api = `${btbk}/fbike/lootbox/base`;
  let o = q_construct("q_lootbox_base", api, doc, {});
  return o;
};

export const q_lootbox_buy_box = (doc, ext) => {
  let api = `${btbk}/fbike/lootbox/buy_box`;
  return q_auconstruct("q_lootbox_buy_box", api, doc, { ...ext });
};
export const q_lootbox_validate_purchase = (doc, ext) => {
  let api = `${btbk}/fbike/lootbox/validate_purchase`;
  return q_auconstruct("q_lootbox_validate_purchase", api, doc, { ...ext });
};
export const q_lootbox_boxes = (doc, ext) => {
  let api = `${btbk}/fbike/lootbox/boxes`;
  return q_auconstruct("q_lootbox_boxes", api, doc, { ...ext });
};
export const q_lootbox_box = (doc, ext) => {
  let api = `${btbk}/fbike/lootbox/box`;
  return q_auconstruct("q_lootbox_box", api, doc, { ...ext });
};

export const q_lootbox_openbox = (doc, ext) => {
  let api = `${btbk}/fbike/lootbox/openbox`;
  return q_auconstruct("q_lootbox_openbox", api, doc, { ...ext });
};

export const q_funds = (doc, ext) => {
  let api = `${btbk}/fbike/info/funds`;
  return q_auconstruct("q_funds", api, doc, { ...ext });
};

export const q_corelootbox_v2_base = (doc, ext = {}) => {
  return q_construct(
    "q_corelootbox_v2_base",
    `${btbk}/fbike/corelootbox_v2/base`,
    doc,
    ext,
  );
};

export const q_corelootbox_v2_txns = (doc, ext = {}) => {
  return q_construct(
    "q_corelootbox_v2_txns",
    `${btbk}/fbike/corelootbox_v2/txns`,
    doc,
    ext,
  );
};

export const q_corelootbox_v2_lootnfts = (doc, ext = {}) => {
  return q_construct(
    "q_corelootbox_v2_lootnfts",
    `${btbk}/fbike/corelootbox_v2/lootnfts`,
    doc,
    ext,
  );
};

export const q_corelootbox_v2_signup_request = (doc, ext = {}) => {
  return q_construct(
    "q_corelootbox_v2_signup_request",
    `${btbk}/fbike/corelootbox_v2/signup_request`,
    doc,
    ext,
  );
};

export const q_skinlootbox_v1_base = (doc, ext = {}) => {
  return q_construct(
    "q_skinlootbox_v1_base",
    `${btbk}/fbike/skinlootbox_v1/base`,
    doc,
    ext,
  );
};

export const q_skinlootbox_v1_info = (doc, ext = {}) => {
  return q_construct(
    "q_skinlootbox_v1_info",
    `${btbk}/fbike/skinlootbox_v1/info`,
    doc,
    ext,
  );
};

export const q_skinlootbox_v1_txns = (doc, ext = {}) => {
  return q_construct(
    "q_skinlootbox_v1_txns",
    `${btbk}/fbike/skinlootbox_v1/txns`,
    doc,
    ext,
  );
};

export const q_skinlootbox_v1_lootnfts = (doc, ext = {}) => {
  return q_construct(
    "q_skinlootbox_v1_lootnfts",
    `${btbk}/fbike/skinlootbox_v1/lootnfts`,
    doc,
    ext,
  );
};

export const q_skingame_info = (doc = {}, ext = {}) => {
  let next = {
    staleTime: 2 * 60 * 1e3,
    refetchInterval: 2 * 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_skingame_info",
    `${btbk}/fbike/skingame/info`,
    doc,
    next,
  );
};

export const q_skingame_txns = (doc, ext = {}) => {
  let next = {
    staleTime: 60 * 1e3,
    refetchInterval: 60 * 1e3,
    ...ext,
  };
  return q_construct(
    "q_skingame_txns",
    `${btbk}/fbike/skingame/txns`,
    doc,
    next,
  );
};

export const q_skingame_burn_validate = (doc, ext = {}) => {
  return q_construct(
    "q_skingame_burn_validate",
    `${btbk}/fbike/skingame/burn_validate`,
    doc,
    ext,
  );
};

export const q_skingame_vault_skins = (doc, ext = {}) => {
  return q_construct(
    "q_skingame_vault_skins",
    `${btbk}/fbike/skingame/vault_skins`,
    doc,
    ext,
  );
};

export const q_skinxmas_info = (doc, ext = {}) => {
  ext = {
    ...ext,

    staleTime: 1 * 60 * 1e3,
    refetchInterval: 1 * 60 * 1e3,
  };
  return q_construct(
    "q_skinxmas_info",
    `${btbk}/fbike/skinxmas/info`,
    doc,
    ext,
  );
};

export const q_skinxmas_txns = (doc, ext = {}) => {
  ext = {
    ...ext,
    staleTime: 1 * 60 * 1e3,
    refetchInterval: 1 * 60 * 1e3,
  };
  return q_construct(
    "q_skinxmas_txns",
    `${btbk}/fbike/skinxmas/txns`,
    doc,
    ext,
  );
};

export const q_skinxmas_txnque_add = (doc, ext = {}) => {
  ext = {
    ...ext,
    staleTime: 1 * 60 * 1e3,
    refetchInterval: 1 * 60 * 1e3,
  };
  return q_construct(
    "q_skinxmas_txnque_add",
    `${btbk}/fbike/skinxmas/txnque_add`,
    doc,
    ext,
  );
};

export const q_token_prices = (doc, ext = {}) => {
  let next = {
    staleTime: 5 * 60 * 1e3,
    refetchInterval: 5 * 60 * 1e3,
    ...ext,
  };
  let o = q_construct(
    "q_token_prices",
    `${btbk}/fbike/token_prices`,
    doc,
    next,
  );
  return o;
};

export const q_bikeburn_txns = (doc, ext = {}) => {
  let next = {
    staleTime: 30 * 1e3,
    refetchInterval: 30 * 1e3,
    ...ext,
  };
  let o = q_construct(
    "q_bikeburn_txns",
    `${btbk}/fbike/bikeburn/txns`,
    doc,
    next,
  );
  return o;
};
export const q_bikeburn_eliglist = (doc, ext = {}) => {
  let next = {
    ...ext,
    staleTime: 30 * 1e3,
    refetchInterval: 30 * 1e3,
  };
  let o = q_construct(
    "q_bikeburn_eliglist",
    `${btbk}/fbike/bikeburn/eliglist`,
    doc,
    next,
  );
  return o;
};
export const q_bikeburn_queadd = (doc, ext = {}) => {
  let next = { ...ext };
  let o = q_construct(
    "q_bikeburn_queadd",
    `${btbk}/fbike/bikeburn/queadd`,
    doc,
    next,
  );
  return o;
};

export const q_bikeburn_est_reward = (doc, ext = {}) => {
  let next = { ...ext };
  let o = q_construct(
    "q_bikeburn_est_reward",
    `${btbk}/fbike/bikeburn/est_reward`,
    doc,
    next,
  );
  return o;
};

export const q_tp_giftcard_txns = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 30 * 1e3,
    refetchInterval: 30 * 1e3,
  };
  return q_construct(
    "q_tp_giftcard_txns",
    `${btbk}/fbike/trails_cards/tp_giftcard/txns`,
    doc,
    ext,
  );
};

export const q_prizebox_txns = (doc = {}, ext = {}) => {
  ext = {
    staleTime: 30 * 1e3,
    refetchInterval: 30 * 1e3,
  };
  return q_construct(
    "q_prizebox_txns",
    `${btbk}/fbike/prizebox/txns`,
    doc,
    ext,
  );
};

export const q_prizebox_pop = (doc = {}, ext = {}) => {
  return q_construct("q_prizebox_pop", `${btbk}/fbike/prizebox/pop`, doc, ext);
};

export const q_que_txn = (doc, ext = {}) => {
  return q_auconstruct("q_que_txn", `${btbk}/fbike/txns_que/add`, doc, ext);
};

export const q_factions_lootbox_baseinfo = (doc, ext = {}) => {
  return q_construct(
    "q_factions_lootbox_baseinfo",
    `${btbk}/fbike/factions/lootbox/baseinfo`,
    doc,
    ext,
  );
};
export const q_factions_lootbox_txns = (doc, ext = {}) => {
  return q_construct(
    "q_factions_lootbox_txns",
    `${btbk}/fbike/factions/lootbox/txns`,
    doc,
    ext,
  );
};
export const q_factions_lootbox_agentinfo_early = (doc, ext = {}) => {
  return q_construct(
    "q_factions_lootbox_agentinfo_early",
    `${btbk}/fbike/factions/lootbox/agentinfo_early`,
    doc,
    ext,
  );
};

export const q_sheet_pages_chainplay = (doc, ext = {}) => {
  return q_construct(
    "q_sheet_pages_chainplay",
    `${btbk}/fbike/sheet_pages/chainplay`,
    doc,
    ext,
  );
};

// /fbike/skins_direct/info
export const q_skins_direct_info = (doc, ext = {}) => {
  return q_construct(
    "q_skins_direct_info",
    `${btbk}/fbike/skins_direct/info`,
    doc,
    ext,
  );
};

// /fbike/skins_direct/txns
export const q_skins_direct_txns = (doc, ext = {}) => {
  return q_construct(
    "q_skins_direct_txns",
    `${btbk}/fbike/skins_direct/txns`,
    doc,
    ext,
  );
};

export const q_vaultinfo = (doc, ext = {}) => {
  ext = {
    staleTime: 1 * 60 * 1e3,
    refetchInterval: 1 * 60 * 1e3,
    ...ext,
  };
  let o = q_construct("q_vaultinfo", `${btbk}/fbike/vault/info`, doc, ext);
  return o;
};

export const q_vaultbikes = (doc, ext = {}) => {
  let o = q_construct("q_vaultbikes", `${btbk}/fbike/vault/bikes`, doc, ext);
  return o;
};

export const q_vaultbikes_cc = (doc, ext = {}) => {
  let o = q_construct(
    "q_vaultbikes_cc",
    `${btbk}/fbike/vault/bikes_cc`,
    doc,
    ext,
  );
  return o;
};

// /fbike/seasonpass/info
export const q_seasonpass_info = (doc, ext = {}) => {
  return q_construct(
    "q_seasonpass_info",
    `${btbk}/fbike/seasonpass/info`,
    doc,
    ext,
  );
};

// /fbike/seasonpass/txns
export const q_seasonpass_txns = (doc, ext = {}) => {
  return q_construct(
    "q_seasonpass_txns",
    `${btbk}/fbike/seasonpass/txns`,
    doc,
    ext,
  );
};

// /fbike/seasonpass/passlist
export const q_seasonpass_passlist = (doc, ext = {}) => {
  return q_construct(
    "q_seasonpass_passlist",
    `${btbk}/fbike/seasonpass/passlist`,
    doc,
    ext,
  );
};

// /fbike/seasonpass/allot
export const q_seasonpass_allot = (doc, ext = {}) => {
  return q_auconstruct(
    "q_seasonpass_allot",
    `${btbk}/fbike/seasonpass/allot`,
    doc,
    ext,
  );
};

// /factions/agents/owns
export const q_factions_agents_owns = (doc = {}, ext = {}) => {
  return q_construct(
    "q_factions_agents_owns",
    `${btbk}/fbike/factions/agents/owns`,
    doc,
    ext,
  );
};
