import { ethers } from "ethers";
import abi from "./abi.json";
import { contractAddress_list } from "../contracts.js";
import { format_ether, getv, iso, nils } from "../../utils/utils.js";

const getgasinfo = async () => ({});

class Contract {
  constructor({ contractAddress, provider, signer, nosigner = false }) {
    this.provider = provider;
    this.signer = signer;
    this.abi = abi;
    this.contractAddress = contractAddress;
    if (nosigner === true)
      this.contract = new ethers.Contract(
        this.contractAddress,
        this.abi,
        provider,
      );
    else
      this.contract = new ethers.Contract(
        this.contractAddress,
        this.abi,
        signer,
      );
  }

  async getParsedLogs(logs) {
    if (!logs) return [];

    let logrows = [];
    for (let log of logs) {
      let par = this.contract.interface.parseLog(log);

      if (nils(par)) continue;

      let name = par.name;
      let o = {
        name,
        logIndex: log.logIndex,
        hash: log.transactionHash,
        blockNumber: log.blockNumber,
      };
      o.id = `${o.hash}:${o.logIndex}`;

      // event MintLootboxEvent(address vault, uint qty, uint boxtype);
      // event OpenLootboxEvent(address vault, uint qty, uint boxtype);

      if (name == "MintLootboxEvent") {
        let a = par.args;
        o.args = {
          vault: a[0].toLowerCase(),
          qty: Number(a[1]),
          boxtype: Number(a[2]),
        };
      } else if (name == "OpenLootboxEvent") {
        let a = par.args;
        o.args = {
          vault: a[0].toLowerCase(),
          qty: Number(a[1]),
          boxtype: Number(a[2]),
        };
      }

      logrows.push(o);
    }
    return logrows;
  }

  async o_burnLootbox(account, qty, boxtype) {
    let tx = await this.contract.o_burnLootbox(account, qty, boxtype);
    let resp = await tx.wait();
    return resp;
  }

  async openLootbox(qty, boxtype) {
    let tx = await this.contract.openLootbox(qty, boxtype);
    //  tx = await tx.wait();
    return tx;
  }

  async accountOwns(account, boxtype) {
    let resp = await this.contract.accountOwns(account, boxtype);
    resp = Number(resp);
    return resp;
  }
}

const chain_mode = "main";
const k = "tp_giftcard";

const get_contract_address = () => {
  return contractAddress_list[k];
};

const get_contract = async (ext = {}) => {
  const contractAddress = get_contract_address();
  const provider = !nils(ext.rpc)
    ? new ethers.JsonRpcProvider(ext.rpc)
    : new ethers.BrowserProvider(window.ethereum);
  const signer = ext.nosigner === true ? null : await provider.getSigner();

  const pars = { contractAddress, provider, signer, ...ext };
  const runner = new Contract(pars);
  return runner;
};

const TP_GiftCard = {
  k,
  get_contract,
  get_contract_address,
};
export default TP_GiftCard;
