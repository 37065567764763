import abi_skinlootbox from "./SkinLootbox_v1/abi.json";
import abimethods_skinlootbox from "./SkinLootbox_v1/abimethods.js";
import abi_skingame from "./SkinGame/abi.json";
import abimethods_skingame from "./SkinGame/abimethods.js";
import abi_prizebox from "./PrizeBox/abi.json";
import abimethods_prizebox from "./PrizeBox/abimethods.js";
import abi_tpgiftcard from "./TP_GiftCard/abi.json";
import abimethods_tpgiftcard from "./TP_GiftCard/abimethods.js";
import abi_bikeburner from "./BIkeBurner/abi.json";
import abimethods_bikeburner from "./BIkeBurner/abimethods.js";
import abi_corelootboxv2 from "./CoreLootbox_v2/abi.json";
import abimethods_corelootboxv2 from "./CoreLootbox_v2/abimethods.js";
import abi_skinsdirect from "./SkinsDirect/abi.json";
import abimethods_skinsdirect from "./SkinsDirect/abimethods.js";
import abi_seasonpass from "./SeasonPass/abi.json";
import abimethods_seasonpass from "./SeasonPass/abimethods.js";

const erc_abis = {
  721: [
    "function transferFrom(address from, address to, uint256 tokenId) external",
    "function approve(address to, uint256 tokenId) public",
    "function isApprovedForAll(address owner, address operator) public view returns (bool)",
    "function setApprovalForAll(address operator, bool approved) public",
  ],
  1155: [
    "function safeTransferFrom(address from, address to, uint256 id, uint256 amount, bytes data) external",
    "function isApprovedForAll(address owner, address operator) public view returns (bool)",
    "function setApprovalForAll(address operator, bool approved) public",
  ],
  20: [
    "function balanceOf(address account) external view returns (uint256)",
    "function transfer(address to, uint256 amount) external returns (bool)",
    "function allowance(address owner, address spender) external view returns (uint256)",
    "function approve(address spender, uint256 amount) external returns (bool)",
  ],
};

const erc_methods_abi = {
  20: {
    balanceOf:
      "function balanceOf(address account) external view returns (uint256)",
    transfer:
      "function transfer(address to, uint256 amount) external returns (bool)",
    allowance:
      "function allowance(address owner, address spender) external view returns (uint256)",
    approve:
      "function approve(address spender, uint256 amount) external returns (bool)",
  },
  721: {
    transferFrom:
      "function transferFrom(address from, address to, uint256 tokenId) external",
    approve: "function approve(address to, uint256 tokenId) public",
    isApprovedForAll:
      "function isApprovedForAll(address owner, address operator) public view returns (bool)",
    setApprovalForAll:
      "function setApprovalForAll(address operator, bool approved) public",
  },
  1155: {
    safeTransferFrom:
      "function safeTransferFrom(address from, address to, uint256 id, uint256 amount, bytes data) external",
    isApprovedForAll:
      "function isApprovedForAll(address owner, address operator) public view returns (bool)",
    setApprovalForAll:
      "function setApprovalForAll(address operator, bool approved) public",
  },
};

export const abimap = {
  dez: erc_abis[20],
  DEZ: erc_abis[20],
  weth: erc_abis[20],
  WETH: erc_abis[20],
  bgc: erc_abis[20],
  BGC: erc_abis[20],

  fbikeskin: erc_abis[721],
  core: erc_abis[721],

  skinlootbox: abi_skinlootbox,
  skingame: abi_skingame,
  skinsdirectv1: abi_skinsdirect,
  prizebox: abi_prizebox,
  tp_giftcard: abi_tpgiftcard,
  bikeburner: abi_bikeburner,
  corelootboxv2: abi_corelootboxv2,
  seasonpass: abi_seasonpass,
};

export const abimethodmap = {
  dez: erc_methods_abi[20],
  DEZ: erc_methods_abi[20],
  weth: erc_methods_abi[20],
  WETH: erc_methods_abi[20],
  BGC: erc_methods_abi[20],
  bgc: erc_methods_abi[20],

  fbikeskin: erc_methods_abi[721],
  core: erc_methods_abi[721],

  skinlootbox: abimethods_skinlootbox,
  skingame: abimethods_skingame,
  skinsdirectv1: abimethods_skinsdirect,
  prizebox: abimethods_prizebox,
  tp_giftcard: abimethods_tpgiftcard,
  bikeburner: abimethods_bikeburner,
  corelootboxv2: abimethods_corelootboxv2,
  seasonpass: abimethods_seasonpass,
};

export default abimap;
