import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { nano, nils } from "../utils/utils.js";
import {
  faCircle,
  faClose,
  faEarth,
  faEye,
  faEyeSlash,
  faF,
  faFire,
  faHammer,
  faMars,
  faTeletype,
  faTimes,
  faVenus,
  faWater,
} from "@fortawesome/free-solid-svg-icons";
import { useNowContext } from "../App.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MoVariants } from "../utils/motion_helper.js";
import _ from "lodash";
import { faMarkdown } from "@fortawesome/free-brands-svg-icons";
import { qiserr, qissuccesss } from "../queries/queries.js";
import { Loader01c } from "./anims.js";

export const Tag = (props) => {
  let pointer = props.onClick || props.redirect ? "cursor-pointer" : "";
  return (
    <a
      {...(props.redirect
        ? {
            href: props.redirect,
            target: props.target ?? "_blank",
          }
        : {})}
      {...{
        className: props.acn ?? "",
      }}
    >
      <div
        {...props}
        onClick={() => {
          if (props.onClick) props.onClick();
        }}
        style={{ ...props.style }}
        className={twMerge(
          "resp-text--2",
          "resp-px-2 resp-py-1 font-bold",
          "resp-mx-1 my-0.5 font-bold",
          `nowrap lg:rounded-md xs:rounded-[0.22rem]`,
          pointer,
          props?.active === false ? "bg-opacity-25" : "",
          props.className,
        )}
      >
        {props.children}
      </div>
    </a>
  );
};

export const Img = ({ className, img }) => {
  return (
    <div className={twMerge("img-obey-cont h-auto", className)}>
      <img src={img} />
    </div>
  );
};

export const Card = ({ className, ...props }) => {
  return (
    <div
      className={twMerge("rounded-md resp-p-4 mb-2 w-max mx-auto", className)}
    >
      {props.children}
    </div>
  );
};

export const HeadC2L = (props) => {
  const Line = (
    <>
      <div
        className={twMerge(
          "h-1 min-w-[5rem] rounded-md px-1 bg-white max-w-[10rem]",
          props.linecn,
        )}
      ></div>
    </>
  );
  return (
    <div className="fr-cc w-full gap-2 mb-2">
      <div className="flex-1"></div>
      {Line}
      <div className="w-max">
        <span
          className={twMerge(
            "text-acc0 text-[2rem] font-digi",
            props.className,
          )}
        >
          {props.children}
        </span>
      </div>
      {Line}
      <div className="flex-1"></div>
    </div>
  );
};

export const BImg = ({ hex_code, className }) => {
  return (
    <>
      <div
        style={{ background: `#${hex_code}` }}
        className={twMerge("w-[4rem] h-[4rem] m-1 rounded-md", className)}
      ></div>
    </>
  );
};

const inpkeydel = 300;

export const InpText = (props) => {
  const {
    contprops = {},
    inpprops = {},
    label,
    setter,
    def_val = "",
    type,
    autoComplete,
  } = props;
  const inpref = useRef();
  const lastkey = useRef(null);
  const nc = useNowContext();
  const diff = nc.now - lastkey.current;
  useEffect(() => {
    if (diff > inpkeydel) {
      let value = inpref.current.value;
      if (setter) setter(value);
    }
  }, [diff > inpkeydel]);
  useEffect(() => {
    inpref.current.value = def_val;
  }, [def_val]);
  const [showpass, set_showpass] = useState(false);
  return (
    <motion.div
      {...contprops}
      onClick={() => inpref.current.focus()}
      className={twMerge("inp-container ", contprops.className)}
    >
      {label && <div className="inp-label">{label}</div>}
      {
        <div className="flex flex-row justify-start">
          <input
            id={props.id}
            placeholder={props.placeholder ?? ""}
            onKeyDown={(e) => {
              lastkey.current = nano();
              if (props.onKeyDown) props.onKeyDown(e);
            }}
            onChange={(e) => {
              lastkey.current = nano();
              if (props.onKeyDown) props.onChange(e);
            }}
            onBlur={(e) => {
              lastkey.current = nano();
              if (props.onKeyDown) props.onBlur(e);
            }}
            ref={inpref}
            type={showpass ? "text" : type || "text"}
            defaultValue={def_val}
            className=""
            autoComplete={autoComplete}
            {...inpprops}
          />
          {type == "password" ? (
            <div
              className="p-1 w-[1.5rem] cursor-pointer"
              onClick={() => {
                if (type == "password") set_showpass(!showpass);
              }}
            >
              <FontAwesomeIcon icon={showpass ? faEyeSlash : faEye} />
            </div>
          ) : (
            <div className="w-[1.5rem]"></div>
          )}
        </div>
      }
    </motion.div>
  );
};

export const Hori_Divider = () => {
  return <div className="my-1 h-1 bg-acc0/30 rounded-md"></div>;
};

export const Dropdown = ({ wrapcn, trigger, triggercn, dropcn, ...props }) => {
  const [hover, set_hover] = useState(false);

  return (
    <div className="relative" onMouseLeave={() => set_hover(false)}>
      <div className={wrapcn}>
        <div
          onMouseEnter={() => set_hover(true)}
          className={twMerge(
            "fr-cc mx-4 gap-1 relative cursor-pointer z-10",
            triggercn,
          )}
        >
          {trigger}
        </div>
        <motion.div
          variants={MoVariants.show_hide}
          animate={hover ? "visible" : "hidden"}
          className={twMerge("absolute w-full z-20", dropcn)}
        >
          {props.children}
        </motion.div>
      </div>
    </div>
  );
};

export const Array2D_Tablecn = {
  td_cn:
    "border-b border-slate-600 px-2 resp-px-2 resp-py-1 text-left min-w-[5rem] resp-text--1",
  th_cn:
    "border-b border-acc0/40 px-2 resp-px-2 resp-py-1 text-center min-w-[5rem] text-acc0 font-bold resp-text--1",
  tr_cn: "",
  table_cn: "border-b border-slate-500 xs:w-full md:min-w-[20rem] my-2 mx-auto",
};
export const tablecn = Array2D_Tablecn;

export const Array2D_Table = ({ ar }) => {
  const { td_cn, th_cn, tr_cn, table_cn } = Array2D_Tablecn;
  return (
    <table className={twMerge(table_cn, "w-max overflow-auto")}>
      <tbody>
        {ar.map((rowdata, rowidx) => (
          <tr className={tr_cn}>
            {rowdata.map((c, cidx) => (
              <td key={cidx} className={twMerge(rowidx == 0 ? th_cn : td_cn)}>
                <p className="whitespace-pre-wrap">{c}</p>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export const Array1D_List = ({ ar }) => {
  return (
    <div className="ml-[3rem]">
      <ul className="list-disc list">
        {ar.map((d) => {
          return (
            <li className="base-text">
              <p className="white-space-pre-text">{d}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export function BikeCard({ bike: b }) {
  return (
    <div className="bg-dark p-2 grid grid-cols-3 gap-2">
      <div className="col-span-1 xs:hidden md:block">
        <BImg hex_code={b.hex_code} className={"w-full h-full max-h-[8rem]"} />
      </div>
      <div className="xs:col-span-3 md:col-span-2">
        <div className="font-digi resp-text-1 text-center my-2 text-acc0">
          {b.name}
        </div>
        <div className="grid grid-cols-2 gap-2">
          {[
            ["Type", _.capitalize(b.type), faTeletype],

            b.gender == "male"
              ? [
                  "Gender",
                  _.capitalize(b.gender),
                  faVenus,
                  "text-blue-400",
                  "text-blue-400",
                ]
              : [
                  "Gender",
                  _.capitalize(b.gender),
                  faVenus,
                  "text-pink-400",
                  "text-pink-400",
                ],

            ["F.No", `F${b.fno}`, faF],
            [
              "Element",
              _.capitalize(b.element),
              ...((b.element == "earth" && [
                faEarth,
                "text-orange-900",
                "text-orange-900",
              ]) ||
                (b.element == "water" && [
                  faWater,
                  "text-blue-400",
                  "text-blue-400",
                ]) ||
                (b.element == "fire" && [
                  faFire,
                  "text-orange-400",
                  "text-orange-400",
                ]) ||
                (b.element == "metal" && [
                  faHammer,
                  "text-slate-900",
                  "text-slate-400",
                ]) ||
                []),
            ],
            [
              "Color",
              _.chain(b.color).split("-").map(_.capitalize).join(" ").value(),
              faCircle,
              `text-${b.color}`,
              "",
            ],
          ].map(([head, txt, icon, icn, cn]) => {
            return (
              <MiniStatCard
                {...{
                  icon,
                  head,
                  txt,
                  icn,
                  cn,
                  wrapcn: head == "Color" ? "col-span-2" : "",
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export const MiniStatCard = ({ icon, head, txt, icn, cn, wrapcn }) => {
  return (
    <div
      className={twMerge(
        "bg-reg shadow shadow-black px-2 py-3 rounded-sm",
        wrapcn,
      )}
    >
      <div className="fr-sc gap-2">
        <div className={twMerge("px-2", icn)}>
          <FontAwesomeIcon className="resp-text-0" icon={icon} />
        </div>
        <div className="flex flex-col justify-center items-start">
          <div className="font-mon resp-text--2 text-slate-300">{head}</div>
          <div
            className={twMerge(
              "font-mon resp-text--1 text-white font-bold",
              cn,
            )}
          >
            {txt}
          </div>
        </div>
      </div>
    </div>
  );
};

export const PopupCloseBtn = ({ closepopup }) => {
  return (
    <div className="absolute top-[-8px] right-[-8px]">
      <Tag
        onClick={() => closepopup()}
        className="fc-cc xs:w-[1rem] xs:h-[1rem] lg:w-[2rem] lg:h-[2rem] bg-red-500 rounded-[2rem]"
      >
        <FontAwesomeIcon icon={faClose} fontSize={"resp-text-2"} />
      </Tag>
    </div>
  );
};
export const tokenimgmap = {
  USD: "https://i.imgur.com/y6kotUk.png",

  WETH: "https://token-icons.s3.amazonaws.com/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png",
  weth: "https://token-icons.s3.amazonaws.com/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png",

  ETH: "https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/512/Ethereum-ETH-icon.png",
  MATIC: "https://polygonscan.com/assets/generic/html/favicon-light.ico",

  DEZ: "https://i.imgur.com/sbvrqAU.png",
  USD: "https://i.imgur.com/y6kotUk.png",
  BGC: "https://cdn.dnaracing.run/imgs/bgc.png",
  SGC: "https://cdn.dnaracing.run/imgs/sgc.png",

  "weth-mock01":
    "https://token-icons.s3.amazonaws.com/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png",

  "WETH-mock-01":
    "https://token-icons.s3.amazonaws.com/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png",

  LC01: "https://imgur.com/YjaFouL.png",
  lc01: "https://imgur.com/YjaFouL.png",

  lc02: "https://imgur.com/YjaFouL.png",

  LC: "https://cdn.dnaracing.run/imgs/lc.png",
  lc: "https://cdn.dnaracing.run/imgs/lc.png",
};

export const TokenIcon = ({ token, className, size = "xs" }) => {
  return (
    <div
      className={twMerge(
        "rounded-full overflow-hidden resp-px-1",
        "aspect-[1/1] fc-cc ",
        size == "xs"
          ? "xs:w-[1rem] lg:w-[2rem]"
          : size == "md"
            ? "xs:w-[1.5rem] lg:w-[3rem]"
            : size == "lg"
              ? "xs:w-[2rem] lg:w-[4rem]"
              : "",
        className,
      )}
    >
      <Img img={tokenimgmap[token]} />
    </div>
  );
};

export const LazyQueryLoad = ({
  qo = null,
  result = null,
  children = null,
}) => {
  return (
    <>
      {qo.isLoading ? (
        <div className="mx-auto">
          <Loader01c size="s" />
        </div>
      ) : qiserr(qo) ? (
        <p className="text-center text-red-300">{qiserr(qo)}</p>
      ) : qissuccesss(qo) && _.isEmpty(result) ? (
        <p className="text-center text-yellow-300">No Bikes Eligible to burn</p>
      ) : qissuccesss(qo) && !_.isEmpty(result) ? (
        <>{children}</>
      ) : null}
    </>
  );
};
