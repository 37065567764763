import React, { useEffect, useMemo, useState } from "react";
import { useAppContext } from "../App.js";
import { useQueries } from "react-query";
import {
  polytxnidlink,
  polytxnlink,
  q_prizebox_txns,
  q_prizebox_pop,
  q_que_txn,
  qiserr,
  qissuccesss,
} from "../queries/queries.js";
import { useMetaContext } from "../wrappers/MetaMaskWrapper.js";
import {
  cdelay,
  dec,
  getv,
  iso,
  iso_format,
  jstr,
  nils,
} from "../utils/utils.js";
import {
  Card,
  Img,
  PopupCloseBtn,
  Tag,
  tablecn,
  tokenimgmap,
} from "../components/utilityComps.js";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { CircularLoader, Loader01c } from "../components/anims.js";
import colors from "tailwindcss/colors";
import { twMerge } from "tailwind-merge";
import PrizeBox from "../contracts/PrizeBox/PrizeBox";
import { metamaskimg, polychainimg } from "../utils/links.js";
import { PopUp } from "../components/popup.js";
import { Link } from "react-router-dom";
import {
  ElementTag,
  FNoTag,
  GenderTag,
  TypeTag,
} from "../components/ShortComps.js";
import { useThirdWebLoginContext } from "./ThirdWebLogin.js";
import { polygon } from "thirdweb/chains";
import {
  t3_contract_call,
  t3_wait_for_txn,
} from "../contracts/contract_funcs.js";

const PrizeboxContext = React.createContext({});
const usePrizeboxContext = () => React.useContext(PrizeboxContext);

const boximg = "https://i.imgur.com/6M8DdOR.png";
const boxtype = 1;

const boxtypemap = {
  1: {
    name: "Prize Box",
    img: "https://i.imgur.com/6M8DdOR.png",
  },
  2: {
    name: "Big Box",
    img: "https://dna-run-public.s3.us-east-2.amazonaws.com/imgs/img_bigbox.png",
  },
};

const asset_img_table = _.chain([
  { idx: "corelootbox_v2", key: "corelootbox_v2" },
  { idx: "custom_1v1_skin", key: "custom_1v1_skin" },
  { idx: "dez_105000", key: "dez_105000" },
  { idx: "dez_1250", key: "dez_1250" },
  { idx: "dez_2000", key: "dez_2000" },
  { idx: "dez_20000", key: "dez_20000" },
  // { idx: "dez_22500", key: "dez_22500" },
  { idx: "dez_2500", key: "dez_2500" },
  { idx: "dez_10000", key: "dez_10000" },
  // { idx: "dez_300000", key: "dez_300000" },
  { idx: "dez_30000", key: "dez_30000" },
  { idx: "dez_3500", key: "dez_3500" },
  { idx: "dez_400", key: "dez_400" },
  { idx: "dez_40000", key: "dez_40000" },
  { idx: "dez_5000", key: "dez_5000" },
  { idx: "dez_6000", key: "dez_6000" },
  { idx: "dez_8000", key: "dez_8000" },
  { idx: "dez_850", key: "dez_850" },
  { idx: "fbike_f2", key: "fbike_f2" },
  { idx: "fbike_f3", key: "fbike_f3" },
  { idx: "fbike_f4", key: "fbike_f4" },
  { idx: "fbike_f5", key: "fbike_f5" },
  { idx: "fbike_f6", key: "fbike_f6" },
  { idx: "fbike_f7", key: "fbike_f7" },
  { idx: "fbike_f8", key: "fbike_f8" },
  { idx: "matic_10", key: "matic_10" },
  { idx: "matic_18", key: "matic_18" },
  { idx: "matic_2", key: "matic_2" },
  { idx: "matic_3", key: "matic_3" },
  { idx: "matic_5", key: "matic_5" },
  { idx: "matic_8", key: "matic_8" },
  { idx: "sgc_400", key: "sgc_400" },
  { idx: "sgc_500", key: "sgc_500" },

  // { idx: 1, key: "dez_2500" },
  { idx: 2, key: "weth_0.0100" },
  { idx: 3, key: "dez_4000" },
  // { idx: 4, key: "dez_5000" },
  // { idx: 5, key: "dez_3500" },
  { idx: 6, key: "weth_0.0120" },
  { idx: 7, key: "weth_0.0200" },
  { idx: 8, key: "weth_0.0500" },
  { idx: 9, key: "weth_0.1500" },
  { idx: 10, key: "weth_0.2500" },
  // { idx: 11, key: "matic_2" },
  // { idx: 12, key: "matic_10" },
  // { idx: 13, key: "matic_5" },
  { idx: 14, key: "bgc_100" },
  { idx: 15, key: "matic_500" },
  { idx: 16, key: "sgc_1000" },
  { idx: 17, key: "matic_250" },
  { idx: 18, key: "bgc_250" },
  { idx: 19, key: "matic_75" },
  // { idx: 20, key: "matic_3" },
  { idx: 21, key: "matic_30" },
  { idx: 22, key: "matic_150" },
  // { idx: 23, key: "matic_8" },
  // { idx: 24, key: "matic_18" },
  // { idx: 25, key: "sgc_400" },
  { idx: 26, key: "bgc_50" },
  // { idx: 27, key: "sgc_500" },
  { idx: 28, key: "matic_425" },
  // { idx: 29, key: "corelootbox_v2" },
  // { idx: 30, key: "custom_1v1_skin" },
  { idx: 30, key: "skin" },
  // { idx: 31, key: "fbike_f2" },
  // { idx: 32, key: "fbike_f3" },
  // { idx: 33, key: "fbike_f4" },
  // { idx: 34, key: "fbike_f5" },
  // { idx: 35, key: "fbike_f6" },
  // { idx: 36, key: "fbike_f7" },
  // { idx: 37, key: "fbike_f8" },
  { idx: "usd_100", key: "usd_100" },
  { idx: "usd_250", key: "usd_250" },
  { idx: "usd_500", key: "usd_500" },
  { idx: "usd_1000", key: "usd_1000" },
  { idx: "usd_5000", key: "usd_5000" },
])
  .keyBy("key")
  .mapValues("idx")
  .value();

const gen_asset_img = (a) => {
  let key = null;
  if (a.type == "USD") {
    key = a.key;
  } else if (["WETH", "DEZ", "MATIC", "SGC", "BGC"].includes(a.type)) {
    let val = a.amt < 1 ? dec(a.amt, 4) : parseInt(a.amt);
    key = `${a.type.toLowerCase()}_${val}`;
  } else if (a.type == "corelootbox_v2") {
    key = "corelootbox_v2";
  } else if (a.type == "custom_1v1_skin") {
    key = "custom_1v1_skin";
  } else if (a.type == "skin") {
    key = `skin`;
  } else if (a.type == "fbike") {
    let fno = getv(a, "info.fno");
    key = `fbike_f${fno}`;
  }
  if (nils(key)) return null;
  let idx = asset_img_table[key];
  if (nils(idx)) return null;
  let imglink = `https://dna-run-public.s3.us-east-2.amazonaws.com/prizebox/${idx}.png`;
  return imglink;
};

const gen_asset_i = (a) => {
  let idx = asset_img_table[a];
  return `https://dna-run-public.s3.us-east-2.amazonaws.com/prizebox/${idx}.png`;
};

const ClosedBox = ({ boxtype }) => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const mmcon = useMetaContext();
  const { vault, auth, aumode } = mmcon;
  const t3con = useThirdWebLoginContext();
  const { thirdweb_client, active_account } = t3con;
  const bmap = boxtypemap[boxtype];

  const pcon = usePrizeboxContext();
  const { qo_txns } = pcon;

  const [resp, set_resp] = useState({});
  const [count_loading, set_count_loading] = useState(true);
  const [loading, set_loading] = useState(false);
  const [closed_n, set_closed_n] = useState(0);

  const update_closed = async () => {
    try {
      set_count_loading(true);
      const con =
        aumode === "thirdweb"
          ? await PrizeBox.get_contract({ nosigner: true, rpc: polygon.rpc })
          : await PrizeBox.get_contract();
      let n = await con.accountOwns(vault, boxtype);
      set_count_loading(false);
      return set_closed_n(n);
    } catch (err) {
      console.log(err);
      set_count_loading(false);
      setTimeout(update_closed, 5 * 1e3);
    }
  };

  useEffect(() => {
    let interval = null;
    if (auth) {
      setTimeout(update_closed, 2 * 1e3);
      interval = setInterval(update_closed, 2 * 60 * 1e3);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [auth]);

  const open_box = async () => {
    try {
      set_loading(true);
      set_resp({});

      const con =
        aumode === "thirdweb"
          ? await PrizeBox.get_contract({ nosigner: true, rpc: polygon.rpc })
          : await PrizeBox.get_contract();

      let resp = null;
      if (aumode === "thirdweb") {
        upd_psearch({ boxopentx: "waiting_hash" });
        resp = await t3_contract_call(
          "prizebox",
          "openLootbox",
          [1, boxtype],
          "txn",
          false,
          { active_account },
        );
        upd_psearch({ boxopentx: resp.hash });
        resp = await t3_wait_for_txn(resp.hash);
      } else {
        upd_psearch({ boxopentx: "confirm_metamask" });
        resp = await con.openLootbox(1, boxtype);
        console.log(resp);
        resp = await resp.wait();
        console.log(resp);
      }

      if (nils(resp.hash)) throw new Error("Transaction failed");
      upd_psearch({ boxopentx: resp.hash });
      await q_que_txn({
        hash: resp.hash,
        vault,
        service: "prizebox",
      }).queryFn();

      await cdelay(5000);
      set_loading(false);
      setTimeout(update_closed, 5 * 1e3);
      setTimeout(qo_txns.refetch, 40 * 1e3);
    } catch (err) {
      console.log(err);
      set_loading(false);
      set_resp({ err: err.message });
    }
  };

  return (
    <>
      {
        <div className=" rounded-md bg-r2reg/40 fr-cc w-max mx-auto">
          <Card
            className={twMerge(
              "resp-p-4 pl-[2rem] overflow-hidden w-max text-center bg-r2dark/20 mb-0",
            )}
          >
            <p className="font-digi resp-text-0 resp-my-2">{bmap.name}</p>
            <div className="fr-cc">
              <div className="relative xs:w-[5rem] md:w-[12rem] xs:h-[5rem] md:h-[12rem] mx-auto ">
                <div
                  style={{
                    background: colors.teal[300],
                  }}
                  className={twMerge(
                    "absolute animate-fade-in xs:w-[5rem] md:w-[12rem] xs:h-[5rem] md:h-[12rem] translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%] z-[5] blur-3xl",
                  )}
                ></div>
                <Img
                  img={bmap.img}
                  className={
                    "absolute xs:w-[4rem] md:w-[12rem] xs:h-[4rem] md:h-[12rem] z-[10]"
                  }
                />
              </div>
              <div className="p-4">
                {!auth ? (
                  <></>
                ) : count_loading ? (
                  <div className="fr-sc my-1">
                    <div className="spin-anim">
                      <FontAwesomeIcon
                        className="resp-text-1"
                        icon={faRefresh}
                      />
                    </div>
                  </div>
                ) : (
                  <p className="text-acc0 font-digi resp-text-2">
                    x {closed_n}
                  </p>
                )}
              </div>
            </div>
            {closed_n > 0 ? (
              <div className="fr-sc my-1">
                <div className="flex-1"></div>
                {loading && <Loader01c size="s" />}
                <Tag
                  onClick={() => {
                    if (loading) return;
                    open_box();
                  }}
                  className="-skew-x-12 text-white font-digi bg-acc4/40"
                >
                  Open 1 Box
                </Tag>
              </div>
            ) : (
              <div className="fr-sc my-1">
                <Tag className="opacity-5">-</Tag>
              </div>
            )}
            {!nils(resp.err) && (
              <div className="fr-sc my-4">
                <p className="text-red-400 resp-text--1">{!nils(resp.err)}</p>
              </div>
            )}
          </Card>
        </div>
      }
    </>
  );
};

const ClosedBoxSection = () => {
  return (
    <div class="fr-sc resp-gap-2">
      <ClosedBox boxtype={1} />
      <ClosedBox boxtype={2} />
    </div>
  );
};

const ViewBoxAssets = ({ t, bmap }) => {
  const [open, set_open] = useState(false);

  const close_popup = () => {
    set_open(false);
  };

  const boxes = useMemo(
    () => getv(t, "connects_post.sel_boxes") || [],
    [jstr(t)],
  );

  return (
    <>
      <Tag onClick={() => set_open(true)} className="bg-acc0/40">
        <span className="resp-text--2">View Box Assets</span>
      </Tag>

      <PopUp
        wrapcn={twMerge("top-[5rem] left-[50%]")}
        innercn={twMerge(" translate-x-[-50%] translate-y-[0%]")}
        openstate={open}
        overlayclose={false}
      >
        <Card
          className={
            "card-basic-bg card-grad-border xs:w-full md:w-[50rem] max-w-[95rem] relative"
          }
        >
          <div className="fr-sc">
            <p className="text-acc0">
              {`${boxes.length} Box${boxes.length > 1 ? "es" : ""}`}
            </p>
            <div className="flex-1"></div>
            <PopupCloseBtn closepopup={close_popup} />
          </div>
          {boxes.map((boxid, i) => {
            let b = bmap[boxid];
            return (
              <div key={boxid}>
                <p className="text-acc0 resp-text-1">
                  #B{i + 1} Box[{boxid}]
                </p>
                {b.assets.map((a, j) => {
                  let m = getv(b, `mintmap.${j}`);
                  let minted = !nils(m) && !nils(m?.txid);
                  let img = gen_asset_img(a);
                  return (
                    <div key={j}>
                      <div className="fr-sc resp-gap-2 my-1 resp-text--1">
                        <p className="font-digi resp-text-1">{`B${
                          i + 1
                        }:asset-${j + 1}`}</p>

                        <div className="flex-1"></div>

                        {minted == true ? (
                          <>
                            <Link
                              target={"_blank"}
                              to={polytxnidlink(m.txid)}
                              className="fr-sc resp-gap-1 text-purple-300"
                            >
                              <div className="xs:w-[1rem] md:w-[2rem]">
                                <Img img={polychainimg} />
                              </div>
                              <span>minted at {iso_format(m.date)}</span>
                            </Link>
                          </>
                        ) : (
                          <>
                            <Loader01c size="s" />
                            <span className="text-acc0">Awaiting mint..</span>
                          </>
                        )}
                      </div>

                      <div className="fc-cc resp-gap-2">
                        <div class="font-digi text-center resp-text-2">
                          {a.type} {a.amt}
                        </div>
                        <div className="xs:w-[8rem] lg:w-[25rem]">
                          {!nils(img) ? <Img img={img} /> : null}
                        </div>
                        {["fbike", "skin", "custim_1v1_skin"].includes(
                          a.type,
                        ) ? (
                          <div className="flex-1">
                            {a.type === "fbike" && (
                              <>
                                {minted == true ? (
                                  <div>
                                    <Link
                                      target="_blank"
                                      to={`https://market.dnaracing.run/asset/core/${getv(
                                        m,
                                        "hinfo.hid",
                                      )}`}
                                    >
                                      <div className="fr-sc resp-gap-2 resp-text--1">
                                        <span className="text-acc0 font-digi resp-text-2">
                                          #{getv(m, "hinfo.hid")}
                                        </span>
                                        <span className="text-white font-digi resp-text-1">
                                          {getv(m, "hinfo.name")}
                                        </span>
                                      </div>
                                      <div className="grid grid-cols-2 resp-gap-2 resp-text--1">
                                        <ElementTag
                                          element={getv(m, "hinfo.element")}
                                        />
                                        <TypeTag type={getv(a, "info.type")} />
                                        <FNoTag fno={getv(m, "hinfo.fno")} />
                                        <GenderTag
                                          gender={getv(m, "hinfo.gender")}
                                        />
                                      </div>
                                    </Link>
                                  </div>
                                ) : (
                                  <>
                                    <p className="resp-text-1 font-digi text-center">
                                      <span>{`You Get 1 random Bike:`}</span>
                                      <span className="text-acc0">
                                        {_.capitalize(getv(a, "info.type"))}{" "}
                                      </span>
                                      <span className="text-acc0">
                                        F{getv(a, "info.fno")}
                                      </span>
                                    </p>
                                  </>
                                )}
                              </>
                            )}
                            {["skin", "custom_1v1_skin"].includes(a.type) && (
                              <>
                                {minted == true ? (
                                  <div>
                                    <Link
                                      target="_blank"
                                      to={`https://market.dnaracing.run/asset/skin/${getv(
                                        m,
                                        "skin_info.skinid",
                                      )}`}
                                    >
                                      <div className="">
                                        <p className="text-acc0 font-digi resp-text-2 my-1">
                                          skinid: #{getv(m, "skin_info.skinid")}
                                        </p>

                                        <p className="text-white  resp-text--1 my-1">
                                          <span className="font-mon">
                                            Rarity:
                                          </span>
                                          <span className="font-digi text-acc0">
                                            {getv(m, "skin_info.rarity")}
                                          </span>
                                        </p>
                                        <p className="text-white font-digi resp-text--1 my-1">
                                          <span className="font-mon">
                                            Order ID:
                                          </span>
                                          <span className="font-digi text-acc0">
                                            {getv(m, "skin_info.name")}
                                          </span>
                                        </p>
                                      </div>
                                    </Link>
                                  </div>
                                ) : (
                                  <></>
                                )}

                                <p className="my-2 text-yellow-300 resp-text--1 w-full p-2 rounded-md border border-yellow-300">
                                  Please Contact Staff via discord in the
                                  support channel
                                </p>
                              </>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      {/* <p>asset: {jstr(a)}</p> */}
                      {/* <p>mintmap: {jstr(m)}</p> */}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </Card>
      </PopUp>
    </>
  );
};

const TransactionTable = () => {
  const pcon = usePrizeboxContext();
  const { qo_txns } = pcon;
  const [txns = [], bmap = {}] = useMemo(() => {
    return [
      getv(qo_txns, "data.result.txns"),
      getv(qo_txns, "data.result.boxinfomap"),
    ];
  }, [qo_txns.dataUpdatedAt]);

  return (
    <Card
      className={twMerge(
        "bg-r2reg/40 mx-auto max-w-[95vw] w-[50rem] my-[2rem]",
      )}
    >
      <p className="resp-text-1 text-acc0 font-digi my-2">Transactions</p>

      <table className={twMerge(tablecn.table_cn, "w-max")}>
        <tbody>
          {txns.map((t, idx) => {
            let boxtype = getv(t, "connects.boxtype");
            return (
              <tr className={twMerge("thintdrowp4 resp-text--2")}>
                <td>
                  <Tag
                    redirect={polytxnidlink(t.id)}
                    className="fr-sc text-purple-300 resp-gap-1"
                  >
                    <div className="xs:w-[0.6rem] lg:w-[1.2rem]">
                      <Img img={polychainimg} />
                    </div>
                    <span>{t.id.slice(0, 5)}...</span>
                  </Tag>
                </td>

                <td>
                  {t.type == "mint" ? (
                    <Tag
                      className={twMerge(
                        "bg-orange-500/30 border border-transparent",
                        boxtype == 2 ? "border-pos1 italic" : "",
                      )}
                    >
                      MINTED {getv(t, "connects.qty")}{" "}
                      {boxtypemap[boxtype]?.name}
                    </Tag>
                  ) : t.type == "open" ? (
                    <Tag
                      className={twMerge(
                        "bg-green-500/30 border border-transparent",
                        boxtype == 2 ? "border-pos1 italic" : "",
                      )}
                    >
                      OPENED {getv(t, "connects.qty")}{" "}
                      {boxtypemap[boxtype]?.name}
                    </Tag>
                  ) : null}
                </td>
                <td>
                  <p className="text-right">{iso_format(getv(t, "date"))}</p>
                </td>
                <td>
                  {t.type == "open" ? (
                    <>
                      <ViewBoxAssets t={t} bmap={bmap} />
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};

const PrizeboxPopupOverlay = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const pcon = usePrizeboxContext();
  const { showopen, set_showopen } = pcon;
  const boxopentx = psearch?.boxopentx;

  const [assigned, set_assigned] = useState(false);
  const [mode, set_mode] = useState(
    boxopentx == "confirm_metamask" ? "promptconfirming" : "txnconfirming",
  );
  useEffect(() => {
    if (mode == "promptconfirming" && boxopentx !== "confirm_metamask")
      set_mode("txnconfirming");
  }, [boxopentx]);

  const [qpop] = useQueries([
    q_prizebox_pop(
      {
        hash: boxopentx,
      },
      assigned
        ? { refetchInterval: 60 * 1e3, staleTime: 60 * 1e3 }
        : { refetchInterval: 5 * 1e3, staleTime: 5 * 1e3 },
    ),
  ]);
  const pdoc = useMemo(() => {
    let d = getv(qpop, "data.result");
    if (_.isEmpty(d)) return {};
    return d;
  }, [qpop.dataUpdatedAt]);

  // const pdoc = { boxtype: 1, prize: "dez_8000", };

  const boximg = getv(boxtypemap, `${pdoc.boxtype}.img`);
  const shineimg = "/imgs/shineback.webp";
  const prizeimg = gen_asset_i(pdoc.prize);
  const chainimg =
    "https://cdn.icon-icons.com/icons2/4044/PNG/512/polygon_blockchain_logo_icon_256922.png";

  const modes = {
    promptconfirming: {
      chain: "",
      confetti: "",
      shine: "",
      main: "",
      prize: "",
    },
    txnconfirming: {
      chain: "",
      confetti: "",
      shine: "",
      main: "",
      prize: "",
    },
    assigning: {
      chain: "",
      confetti: "",
      shine: "",
      main: "",
      prize: "",
    },
    locked: {
      confetti: "opacity-0",
      shine: "prizebox-backshine-anim",
      main: "prizebox-main-hover",
      prize: "opacity-0 pointer-events-none",
    },
    revealing: {
      confetti: "opacity-1",
      shine: "prizebox-backshine-anim",
      main: "prizebox-main-revealing",
      prize: "prizebox-confetti",
    },
  };

  const [per, set_per] = useState(0);
  const [fillspeed, set_fillspeed] = useState(100);

  const fillfast = () => {
    setTimeout(() => {
      set_fillspeed(5);
    }, 5 * 1e3);
  };

  useEffect(() => {
    let a = !_.isEmpty(pdoc);
    if (a) {
      fillfast();
    }
    set_assigned(a);
  }, [pdoc]);

  useEffect(() => {
    if (assigned === true && per >= 100) {
      setTimeout(() => {
        set_mode("locked");
      }, 2 * 1e3);
      return;
    }
    let time = null;
    if (mode == "txnconfirming")
      time = setTimeout(() => {
        set_per((p) => p + 0.1);
      }, fillspeed);
    return () => {
      if (time) clearTimeout(time);
    };
  }, [per, fillspeed, assigned]);

  const close_popup = () => {
    set_showopen(false);
    upd_psearch({ boxopentx: null });
  };

  // setTimeout(() => {
  //   upd_psearch({
  //     boxopentx:
  //       "0xbeab8b54feb2537ab4219b1040bc1677484e85591db312277cd16819537f6cda",
  //   });
  // }, [5 * 1e3]);

  return (
    <>
      <div class="absolute top-[0] left-[0] w-[100vw] h-[100vh] xs:p-[1rem] lg:p-[4rem] z-[100] backdrop-blur-sm">
        <div class="bg-black/60 w-full h-full p-[2rem] fc-cc rounded-md fc-cc backdrop-blur-md relative">
          <PopupCloseBtn closepopup={close_popup} />
          <div class="xs:scale-[0.55] md:scale-[0.8] lg:scale-1 h-[40rem] w-[40rem] relative">
            <div
              class={twMerge(
                mode == "promptconfirming"
                  ? "opacity-1"
                  : "opacity-0 pointer-events-none",
                "chain-section absolute top-0 left-0",
              )}
            >
              <div
                class={twMerge("h-[40rem] w-[40rem] aspect-[1/1] relative ")}
              >
                <div class="absolute h-[30rem] top-[5rem] left-[5rem] z-[101] aspect-[1/1]">
                  <Img className={""} img={metamaskimg} />
                </div>
                <p class="resp-text-2 text-orange-400 font-digi text-center absolute w-full bottom-0">
                  Confirm MetaMask Txn
                </p>
              </div>
            </div>

            <div
              class={twMerge(
                mode == "txnconfirming"
                  ? "opacity-1"
                  : "opacity-0 pointer-events-none",
                "chain-section absolute top-0 left-0",
              )}
            >
              <div
                class={twMerge("h-[40rem] w-[40rem] aspect-[1/1] relative ")}
              >
                <div class="absolute h-[30rem] top-[5rem] left-[5rem] z-[101] aspect-[1/1]">
                  <Img className={""} img={chainimg} />
                </div>
                <div class="h-[100%] aspect-[1/1] z-[102] absolute top-[0] left-[0]">
                  <CircularLoader
                    hexcode="#7346C0"
                    className={"h-full"}
                    per={per}
                  />
                </div>
              </div>
            </div>

            <div
              class={twMerge(
                mode == "locked" || mode == "revealing"
                  ? "opacity-1 pointer-events-all"
                  : "opacity-0 pointer-events-none",
                "box-section absolute top-0 left-0",
              )}
            >
              <div
                class={twMerge(
                  "h-[40rem] w-[40rem] aspect-[1/1] relative transition-500",
                )}
              >
                <div
                  class={`absolute h-[50rem] transition-500 translate-x-[-5rem] translate-y-[-5rem] aspect-[1/1] z-[104] pointer-events-none ${modes[mode].confetti}`}
                >
                  <Img img={"https://i.gifer.com/ZOHV.gif"} />
                </div>
                <div
                  class={`absolute h-[40rem] aspect-[1/1] ${modes[mode].shine} z-[101]`}
                >
                  <Img img={shineimg} />
                </div>
                <div
                  onClick={() => {
                    set_mode("revealing");
                  }}
                  class={`absolute cursor-pointer top-[5rem] left-[5rem] h-[30rem] aspect-[1/1] ${modes[mode].main} z-[102]`}
                >
                  <Img img={boximg} />
                </div>

                <div
                  class={`absolute h-[40rem] fc-cc aspect-[1/1] relative z-[101] ${modes[mode].prize} z-[103]`}
                >
                  <Img className={"h-[25rem]"} img={prizeimg} />
                </div>
              </div>
            </div>
          </div>
          <div class="h-[20vh]"></div>
        </div>
      </div>
    </>
  );
};

export const PrizeboxPage = () => {
  const appcon = useAppContext();
  const { psearch, upd_psearch } = appcon;

  const mmcon = useMetaContext();
  const { vault, auth } = mmcon;

  const [qo_txns] = useQueries([
    q_prizebox_txns({ vault }, { enabled: !nils(vault) }),
  ]);

  const [showopen, set_showopen] = useState(false);
  useEffect(() => {
    if (!nils(psearch.boxopentx)) {
      // setTimeout(() => {
      set_showopen(true);
      // }, 2 * 1e3);
    }
  }, [psearch.boxopentx]);

  const pcon = { vault, qo_txns, set_showopen };

  return (
    <PrizeboxContext.Provider value={pcon}>
      <div className="h-page">
        {showopen ? <PrizeboxPopupOverlay /> : null}
        <div className="mx-auto w-[50rem] max-w-[95vw]">
          <div className="h-[2rem]"></div>
          <p className="text-center text-acc0 font-digi resp-text-2">
            Prize Box
          </p>
          <div className="h-[1rem]"></div>

          {!auth ? (
            <>
              <div className="fc-cc resp-gap-4">
                <p className="text-center text-acc0 font-digi resp-text-2">
                  Please connect your wallet to continue
                </p>

                <Tag
                  onClick={mmcon.login}
                  className={twMerge(
                    "resp-text--1 fr-sc resp-gap-1 bg-acc0/40 -skew-x-12",
                  )}
                >
                  <div className="xs:w-[1rem] lg:w-[2rem]">
                    <Img img={metamaskimg} />
                  </div>
                  <span>Connect Wallet</span>
                </Tag>
              </div>
            </>
          ) : (
            <>
              <ClosedBoxSection />
              <div class="h-[2rem] "></div>
              {qo_txns.isLoading ? (
                <Loader01c size="s" />
              ) : qiserr(qo_txns) ? (
                <p className="text-center text-red-300 resp-text--1">
                  {qiserr(qo_txns)}
                </p>
              ) : qissuccesss(qo_txns) &&
                _.isEmpty(getv(qo_txns, "data.result.txns")) ? (
                <p className="text-center text-yellow-300 resp-text--1">
                  No transactions found
                </p>
              ) : qissuccesss(qo_txns) &&
                !_.isEmpty(getv(qo_txns, "data.result.txns")) ? (
                <TransactionTable />
              ) : null}
            </>
          )}

          <div className="h-[5rem]"></div>
        </div>
      </div>
    </PrizeboxContext.Provider>
  );
};
